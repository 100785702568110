import React, { useState, useEffect } from "react";
import { CiLocationOn } from "react-icons/ci";
import CardHeader from "./CardHeader";
import { useLocation, useNavigate } from "react-router";
import { Formik, Form, Field, ErrorMessage  } from "formik";
import * as Yup from "yup";
import { setLocation, setProgress } from "../redux/features/ticketSlice";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { api } from "../api/auth";
import { toast } from "react-toastify";
import Logo from "../assets/svgs/Logo-2.svg";
import StepProgress from "./StepProgress";

const Venue = ({ nextStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useSelector((state) => state.tickets.location);
  const [venue, setVenue] = useState("" || location);
  const locationUrl = useLocation();
  const searchParams = new URLSearchParams(locationUrl.search);
  const ticketType = searchParams.get("ticketType");

  const validationSchema = Yup.object().shape({
    venue: Yup.string().required("Please select a venue"),
  });

  const initialValues = {
    venue: "",
  };

  useEffect(() => {
    sessionStorage.setItem("show-progress", "show-progress");
  }, []);

  const handleSubmit = (values) => {
    nextStep(values);

    dispatch(setLocation(venue));
  };

  const { data:locationRes } = useQuery(
    ["get-space", venue],
    async () => {
      if (venue) {
        // Check if the venue is selected
        const response = await api.getFreeSpace(venue);
        dispatch(setProgress(response.free_space_in_percentage));
        return response;
      }
    },
    {
      enabled: !!venue, // Enable the query only when venue is selected
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );

  
  const { data } = useQuery(
    ["log-users"],
    async () => {   
        const response = await api.logVisitors();
        return response;      
    },
  );

  return (
    <div className="body">
      <div className="containerOuter">
        <div className="">
          <div className="containerInner">
            <div>
              <div className="hidden md:flex justify-center md:mb-5">
                <img className="w-20 h-auto" src={Logo} alt="quiz-logo" />
              </div>
              <h3 className="heading">STEM Africa Fest 2024</h3>
              <div className="mb-7">
                <div className="flex justify-between items-center">
                  <div className="">
                    <h1 className="text-base font-semibold text-black100">
                      {ticketType === "individual"
                        ? "Individual Tickets"
                        : ticketType === "group"
                        ? "Group Tickets"
                        : ticketType === "family"
                        ? "Family Tickets"
                        : "Unknown Ticket Type"}
                    </h1>

                    <p className="text-sm font-medium text-grey100">
                      Sponsor details
                    </p>
                  </div>
                  <div className={``}>
                    <StepProgress />
                    <p className="text-center text-xs font-medium text-grey100 mt-1">
                      Tickets available
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <CardHeader /> */}
            <Formik
              initialValues={{ venue: venue }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, handleChange }) => (
                <Form>
                  <div className="mb-4 ">
                    <div className="grid grid-cols-2 gap-4">
                      <div
                        className={`w-full cursor-pointer  rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-sm hover:border-primary has-[:checked]:border-primary has-[:checked]:ring-1 has-[:checked]:ring-primary ${
                          venue === "abuja" ? "border-primary" : ""
                        }`}
                        onClick={() => {
                          toast.error("The Abuja event has already taken place.");
                          // setVenue("abuja");
                          // handleChange("venue")("abuja");
                        }}
                      >
                        <Field
                          type="radio"
                          name="venue"
                          value={"abuja"}
                          className={`flex items-start relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300
                    before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]`}
                        />
                        <div className="flex justify-center">
                          <CiLocationOn className="" />
                        </div>
                        <div className="flex flex-col text-center">
                          <h4 className="font-semibold text-lg text-black500 mb-4">
                            Abuja
                          </h4>
                          <p className="text-xs font-medium">Select venue</p>
                        </div>
                      </div>
                      <div
                        className={`w-full cursor-pointer  rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-sm hover:border-primary has-[:checked]:border-primary has-[:checked]:ring-1 has-[:checked]:ring-primary ${
                          venue === "lagos" ? "border-primary" : ""
                        }`}
                        onClick={() => {
                          setVenue("lagos");
                          handleChange("venue")("lagos");
                        }}
                      >
                        <Field
                          type="radio"
                          name="venue"
                          value={"lagos"}
                          className={`flex items-start relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300
                    before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]`}
                        />
                        <div className="flex justify-center">
                          <CiLocationOn className="" />
                        </div>
                        <div className="flex flex-col text-center">
                          <h4 className="font-semibold text-lg text-black500 mb-4">
                            Lagos
                          </h4>
                          <p className="text-xs font-medium">Select venue</p>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage name="venue" component="div" className="text-red-500 text-center mt-1" />
                  </div>
                  <div className="flex flex-col md:flex-row items-center justify-between w-full gap-4 mt-8">
                    <button
                      type="submit"
                      className="py-3 px-4 bg-primary hover:bg-primary/70 focus:ring-primary focus:ring-offset-indigo-200 text-white w-full   transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-2"
                    >
                      Continue
                    </button>
                    <button
                      type="button"
                      onClick={() => navigate(-1)}
                      className="py-3 px-4 bg-white border border-primaryGrey text-grey100 w-full md:w-1/2 transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-1"
                    >
                      Go back
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Venue;
