import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { createSelector } from "reselect";



// Helper function to calculate if a date is three weeks before
export const isThreeWeeksBefore = (eventDate) => {
  const mainDate = new Date(eventDate);
  const currentDate = new Date();
  const differenceInMilliseconds = mainDate.getTime() - currentDate.getTime();
  const threeWeeksInMilliseconds = 1000 * 60 * 60 * 24 * 7 * 3;
  return differenceInMilliseconds > threeWeeksInMilliseconds;
};

const abujaDate = new Date("2024-07-13");
const lagosDate = new Date("2024-07-20");



export const ticketSlice = createSlice({
  name: "tickets",
  initialState: {
    age3to7: {
      quantity: 0,
      ticketPrice: 0,
      total: 0,
    },
    age8to12: {
      quantity: 0,
      ticketPrice: 0,
      total: 0,
    },
    age13to15: {
      quantity: 0,
      ticketPrice: 0,
      total: 0,
    },
    adult: {
      quantity: 0,
      ticketPrice: 0,
      total: 0,
    },
    child: {
      quantity: 0,
      ticketPrice: 0,
      total: 0,
    },

    form: {},
    user: {},
    location: "",
    progress: 0,
    total: 0,
    ticketArray: [],
    ticketType: ""
  },
  reducers: {
    incrementQuantity: (state, action) => {
      const { category } = action.payload;
      if (state[category]) {
        state[category].quantity += 1;
        state[category].total =
          state[category].quantity * state[category].ticketPrice;
        state.total += state[category].ticketPrice;
        toast.success("Ticket added successfully");
      }
    },
    decrementQuantity: (state, action) => {
      const { category } = action.payload;
      const ticketCategory = state[category];
      if (ticketCategory && ticketCategory.quantity > 0) {
        ticketCategory.quantity -= 1;
        state.total -= ticketCategory.ticketPrice;
        state[category].total =
          state[category].quantity * state[category].ticketPrice;
        toast.warning("Ticket removed successfully");
      }
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setForm: (state, action) => {
      state.form = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setTicketArray: (state, action) => {
      state.ticketArray = action.payload;
    },
    setTicketType: (state, action) => {
      state.ticketType = action.payload;
    },
  },
});

export const {
  incrementQuantity,
  decrementQuantity,
  setLocation,
  setForm,
  setProgress,
  setUser,
  setTicketArray,
  setTicketType
} = ticketSlice.actions;



export const getAmount = createSelector(  
  (state) => state.tickets,
  (tickets) => {
    const { age3to7, age8to12, age13to15, adult, child, location, ticketType } = tickets;
    const totalQuantityOfTicket =
      age3to7.quantity +
      age8to12.quantity +
      age13to15.quantity +
      adult.quantity +
      child.quantity;

    const earlyBirdDiscount = isThreeWeeksBefore(
      location === "lagos" ? lagosDate : abujaDate
    );

  
    let baseTicketPrice;

    // if (earlyBirdDiscount) {
    //   baseTicketPrice = 5000;
    // } else if (totalQuantityOfTicket > 4) {
    //   baseTicketPrice = 5000;
    // } else {
    //   baseTicketPrice = 5000;
    // }

    if (ticketType === "individual") {
      baseTicketPrice = 5000;
      // console.log("Individual price applied");
    } else if (ticketType === "family") {
      baseTicketPrice = 4500;
      // console.log("Family price applied");
    } else if (ticketType === "group") {
      baseTicketPrice = 4000;
      // console.log("Group price applied");
    } else {
      baseTicketPrice = 5000;
      // console.log("Default price applied");
    }

    // Calculate total cost for each age group
    const totalAge3to7 = age3to7.quantity * baseTicketPrice;
    const totalAge8to12 = age8to12.quantity * baseTicketPrice;
    const totalAge13to15 = age13to15.quantity * baseTicketPrice;
    const totalAdult = adult.quantity * baseTicketPrice;
    const totalChild = child.quantity * baseTicketPrice;

    // Calculate total amount including all age groups
    const totalAmount =
      totalAge3to7 + totalAge8to12 + totalAge13to15 + totalAdult + totalChild;

    return {
      totalAmount: totalAmount,
      totalAge3to7: totalAge3to7,
      totalAge8to12: totalAge8to12,
      totalAge13to15: totalAge13to15,
      totalAdult: totalAdult,
      totalChild: totalChild,
    };
  }
);

export const getTotalQuantity = (state) => {
  const { age3to7, age8to12, age13to15, adult, child } = state.tickets;
  return (
    age3to7.quantity +
    age8to12.quantity +
    age13to15.quantity +
    adult.quantity +
    child.quantity
  );
};

export default ticketSlice.reducer;
