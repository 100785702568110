import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";

const StepProgress = () => {
  const progress = useSelector((state) => state.tickets.progress);

  return (
    <div>
      <div className="">
        <div className="flex justify-center">
          <div className="w-16 h-16 ">
            <CircularProgressbar
              value={parseInt(progress)}
              text={`${progress}%`}
              counterClockwise
              strokeWidth={4} // Adjust the strokeWidth to control the thickness of the progress bar
              styles={{
                root: {},
                path: {
                  // Customize the progress bar color
                  stroke: `#DE4699`,
                },
                trail: {
                  // Customize the trail color (background of the progress bar)
                  stroke: "#F4F4F5",
                },
                text: {
                  // Customize the text appearance
                  fill: "#1A1A1AB2",
                  fontSize: "20px",
                  fontWeight: "bold",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepProgress;
