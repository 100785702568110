import React from "react";
import Logo from "../assets/svgs/Logo-2.svg";
import moment from "moment/moment";

const Ticket = ({ ticket }) => {
  
  return (
    <div id="test-pdf" className="w-[400px] mb-10 font-['Lato']">
      <div
        className={`flex justify-between items-center px-4 py-2  ${
          ticket?.age_range === "3-7"
            ? "bg-[#00A399]"
            : ticket?.age_range === "adult"
            ? "bg-[#9246DE]"
            : ticket?.age_range === "child"
            ? "bg-[#0087AB]"
            : ticket?.age_range === "13-15"
            ? "bg-[#EE672D]"
            : ticket?.age_range === "8-12"
            ? "bg-[#DE4699]"
            : ""
        }`}
      >
        <div>
          <h1 className="font-bold text-xs text-white font-['Lemonada']">
            {ticket?.age_range === "3-7"
              ? "Age (3 - 7) "
              : ticket?.age_range === "adult"
              ? "Adult"
              : ticket?.age_range === "child"
              ? "Child (Guest)"
              : ticket?.age_range === "13-15"
              ? "Age (12 - 15) Participant"
              : ticket?.age_range === "8-12"
              ? "Age (8 - 12) Participant"
              : ""}
          </h1>
        </div>
        <div>
          <img className="w-20 h-auto" src={Logo} alt="quiz-logo" />
        </div>
      </div>
      <div className="flex justify-between p-4">
        <div className="flex flex-col">
          <p className="font-normal text-xs">Ticket code:</p>
          <p className="font-bold text-sm text-black100">
            {ticket?.ticket_code}
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-grey100 font-bold text-xs">Sponsor:</p>
          <p className="font-bold text-sm text-black100">
            {" "}
            {ticket?.sponsor_name.charAt(0).toUpperCase() +
              ticket?.sponsor_name.slice(1)}
          </p>
        </div>
      </div>
      <div className="mb-6 flex justify-between p-4">
        <div>
          <p className="font-bold text-sm text-black100">Venue:</p>
          <p className="text-sm font-medium text-black300">{ticket?.venue}</p>
        </div>
        <div>
          <p className="text-grey100 font-bold text-xs">Time</p>
          <p className="font-bold text-sm text-[#DE4699] font-['Lemonada']">
            {ticket?.event_time}
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="p-4">
          <p className="text-primary font-bold text-lg font-['Lemonada']">
            {" "}
            {moment(ticket?.event_date).format("DD-MMMM-YYYY")}
            {}
          </p>
          <p className="text-sm font-normal text-[#030304] font-['Lemonada']">
            Ticket Price:{" "}
            <span className="font-bold text-[#030304]">
              {" "}
              ₦ {ticket?.ticket_price}
            </span>
          </p>
        </div>
      </div>
      <div className="flex justify-between p-4 ">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col">
            <p className="text-[#777980] font-bold text-xs mb-1">
              Ticket type:
            </p>
            <p className="font-bold text-sm text-black100">
              {ticket?.ticket_type}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="text-[#777980] font-bold text-xs mb-1">Order no:</p>
            <p className="font-bold text-sm text-black100">
              {ticket?.order_number}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="text-[#777980] font-bold text-xs mb-1">Ticket No:</p>
            <p className="font-bold text-sm text-black100">
              {ticket?.ticket_number}
            </p>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-[#030304] font-extrabold text-sm">ADMITS ONE</p>
          <div className="my-2">
            <img
              className="h-[92px] w-[92px]"
              src={ticket?.qr_code}
              alt="qr-code"
            />
          </div>
          <div className="flex flex-col">
            <p className="text-black100 font-normal text-xs mb-1">
              Ticket code:
            </p>
            <p className="font-bold text-sm text-black">{ticket?.ticket_code}</p>
          </div>
        </div>
      </div>
      <div className="my-2">
        <img className="h-full w-full" src={ticket?.bar_code} alt="bar-code" />
      </div>
    </div>
  );
};

export default Ticket;
