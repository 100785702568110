import React, { useState } from "react";
import CardHeader from "./CardHeader";
import { TiInfoOutline } from "react-icons/ti";
import { HiOutlinePlus, HiOutlineMinusSmall } from "react-icons/hi2";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "antd";
import {
  incrementQuantity,
  decrementQuantity,
  getAmount,
  getTotalQuantity,
  isThreeWeeksBefore,
  setUser,
} from "../redux/features/ticketSlice";
import { useLocation } from "react-router-dom";
import { api } from "./../api/auth";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import TermsAndConditionModal from "./TermsAndConditionModal";

const { Option } = Select;
const SelectTicket = ({ nextStep, prevStep }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const locations = useLocation();
  const searchParams = new URLSearchParams(locations.search);
  const ticketType = searchParams.get("ticketType");

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };
  // useEffect(() => {
  //   sessionStorage.removeItem("show-progress");
  // }, []);

  //quantity display
  const quantity = useSelector((state) => state.tickets.age3to7.quantity);
  const age8Quantity = useSelector((state) => state.tickets.age8to12.quantity);
  const age12Quantity = useSelector(
    (state) => state.tickets.age13to15.quantity
  );
  const adultQuantity = useSelector((state) => state.tickets.adult.quantity);
  const childQuantity = useSelector((state) => state.tickets.child.quantity);

  const sponsorDetails = useSelector((state) => state.tickets.form);

  const location = useSelector((state) => state.tickets.location);
  //   const totalAmount = useSelector((state) => state.tickets.total);
  const totalQuantity = useSelector(getTotalQuantity);
  const totalAmount = useSelector(getAmount);

  // for checkboxes
  const [checkedBoxes, setCheckedBoxes] = useState({
    // quiz: false,
    marketing: false,
    terms: false,
  });
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedBoxes({ ...checkedBoxes, [name]: checked });
  };
  const isContinueButtonDisabled =
    !checkedBoxes.marketing || !checkedBoxes.terms;
  // !checkedBoxes.marketing || !termsChecked
  // end

  const abujaDate = new Date("2024-07-13");
  const lagosDate = new Date("2024-07-20");

  const showAmount = (location) => {
    const earlyBirdDiscount = isThreeWeeksBefore(
      location === "lagos" ? lagosDate : abujaDate
    );

    let baseTicketPrice;

    // if (earlyBirdDiscount) {
    //   baseTicketPrice = 3500;
    //   console.log("Early bird price applied");
    // }
    if (ticketType === "individual") {
      baseTicketPrice = 5000;
    } else if (ticketType === "family") {
      baseTicketPrice = 4500;
    } else if (ticketType === "group") {
      baseTicketPrice = 4000;
    } else {
      baseTicketPrice = 5000;
    }
    return baseTicketPrice;
  };

  const ticketPrice = showAmount();

  const handleIncrement = (category) => {
    if (ticketType === "individual" && totalQuantity >= 3) {
      return;
    }
    if (ticketType === "family" && totalQuantity >= 10) {
      return;
    }
    dispatch(incrementQuantity({ category }));
  };

  const handleDecrement = (category) => {
    dispatch(decrementQuantity({ category }));
  };

  const handleSubmit = async () => {
    if (ticketType === "group" && totalQuantity < 11) {
      toast.warning("Please select more than 10 tickets before proceeding.");
      return;
    }
    if (ticketType === "individual" && totalQuantity > 3) {
      toast.warning("Individual tickets quantity cannot be more than 3.");
      return;
    }
    if (ticketType === "family" && (totalQuantity < 4 || totalQuantity > 10)) {
      toast.warning("Family Tickets quantity must be between 4 and 10.");
      return;
    }

    const payload = {
      amount: totalAmount.totalAmount,
      location: location,
      sponsor_name: sponsorDetails.name,
      sponsor_email: sponsorDetails.email,
      sponsor_phone_number: sponsorDetails.phoneNumber,
      attending: sponsorDetails.type,
      accept_all_consent: true,
      marketing_channel: selectedCategory,
      tickets: [
        {
          type: "participant",
          age_range: "3-7",
          quantity: quantity,
        },
        {
          type: "participant",
          age_range: "8-12",
          quantity: age8Quantity,
        },
        {
          type: "participant",
          age_range: "13-15",
          quantity: age12Quantity,
        },
        {
          type: "participant",
          age_range: "adult",
          quantity: adultQuantity,
        },
        {
          type: "participant",
          age_range: "child",
          quantity: childQuantity,
        },
      ],
    };
    setLoading(true);
    try {
      const response = await api.register(payload);
      toast.success(response.message);
      sessionStorage.setItem("user", JSON.stringify(response?.user));
      if (response.success === true) {
        window.location.assign(response.authorizationUrl);
      }
      dispatch(setUser(response.user));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="body overflow-scroll ">
      <div className="containerOuter">
        <div className="md:mt-20 mb-10">
          <div className="w-full min-h-[12rem] md:w-[500px] bg-white md:rounded-[20px] flex flex-col justify-center p-4 md:p-8 relative">
            <CardHeader />
            <div className="  bg-primary p-2 text-white mb-4">
              <div className="flex space-x-4 justify-center items-center">
                <TiInfoOutline />
                <p className="font-semibold text-xs">
                  Please note that tickets do not cover refreshments <br />
                </p>
              </div>
              <p className="font-semibold text-xs text-center mt-1 italic">
                Children under 15 years MUST be accompanied by an adult
              </p>
            </div>
            <div>
              <div className="flex justify-between mb-4">
                <p className="text-sm font-bold text-[#777980]">
                  Tickets selected
                </p>
                <div className="bg-primary text-white font-semibold text-base px-2 rounded">
                  {totalQuantity}
                </div>
              </div>

              <div>
                <hr />
                <div className="flex justify-between items-center my-2">
                  <div className="flex flex-col">
                    <p className="text-base font-medium">
                      Age 3 - 7
                      {/* <span className="text-grey100 text-sm">(no crèche)</span> */}
                    </p>
                    {/* <p className="text-black500 font-medium text-base">Free</p> */}
                    <div className={`flex`}>
                      {ticketPrice < 5000 && (
                        <p className="line-through  text-red-500 mr-2">
                          <span className="text-grey100">₦ 5,000</span>
                        </p>
                      )}

                      <p className="text-black500 font-medium text-base">
                        ₦ {ticketPrice}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="inline-flex items-center mt-2">
                      <button
                        onClick={() => handleDecrement("age3to7")}
                        className="bg-white rounded-l-md border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlineMinusSmall />
                      </button>
                      <div className="bg-gray-100 border-t border-b border-gray-100 text-gray-600 hover:bg-gray-100 inline-flex items-center px-4 py-1 select-none">
                        {quantity}
                      </div>
                      <button
                        onClick={() => handleIncrement("age3to7")}
                        className="bg-white rounded-r-md border text-gray-600
                        hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50
                        inline-flex items-center p-2 border-r border-primary"
                        disabled={
                          (ticketType === "individual" && totalQuantity >= 3) ||
                          (ticketType === "family" && totalQuantity >= 10)
                        }
                      >
                        <HiOutlinePlus />
                      </button>
                    </div>
                    <p className="text-end mt-2 font-semibold text-lg text-black200">
                      ₦ {totalAmount.totalAge3to7.toLocaleString()}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="flex justify-between items-center my-2">
                  <div className="flex flex-col">
                    <p className="text-base font-medium">
                      Age 8 - 12
                      {/* <span className="text-grey100 text-sm">
                        {" "}
                        (Participant)
                      </span> */}
                    </p>
                    <div className={`flex`}>
                      {ticketPrice < 5000 && (
                        <p className="line-through  text-red-500 mr-2">
                          <span className="text-grey100">₦ 5,000</span>
                        </p>
                      )}

                      <p className="text-black500 font-medium text-base">
                        ₦ {ticketPrice}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="inline-flex items-center mt-2">
                      <button
                        onClick={() => handleDecrement("age8to12")}
                        className="bg-white rounded-l border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlineMinusSmall />
                      </button>
                      <div className="bg-gray-100 border-t border-b border-gray-100 text-gray-600 hover:bg-gray-100 inline-flex items-center px-4 py-1 select-none">
                        {age8Quantity}
                      </div>
                      <button
                        onClick={() => handleIncrement("age8to12")}
                        disabled={
                          (ticketType === "individual" && totalQuantity >= 3) ||
                          (ticketType === "family" && totalQuantity >= 10)
                        }
                        className="bg-white rounded-r border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlinePlus />
                      </button>
                    </div>
                    <p className="text-end mt-2 font-semibold text-lg text-black200">
                      ₦ {totalAmount.totalAge8to12.toLocaleString()}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="flex justify-between items-center my-2">
                  <div className="flex flex-col">
                    <p className="text-base font-medium">
                      Age 13 - 15
                      {/* <span className="text-grey100 text-sm">
                        {" "}
                        (Participant)
                      </span> */}
                    </p>
                    <div className={`flex`}>
                      {ticketPrice < 5000 && (
                        <p className="line-through  text-red-500 mr-2">
                          <span className="text-grey100">₦ 5,000</span>
                        </p>
                      )}

                      <p className="text-black500 font-medium text-base">
                        ₦ {ticketPrice}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="inline-flex items-center mt-2">
                      <button
                        onClick={() => handleDecrement("age13to15")}
                        className="bg-white rounded-l border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlineMinusSmall />
                      </button>
                      <div className="bg-gray-100 border-t border-b border-gray-100 text-gray-600 hover:bg-gray-100 inline-flex items-center px-4 py-1 select-none">
                        {age12Quantity}
                      </div>
                      <button
                        onClick={() => handleIncrement("age13to15")}
                        disabled={
                          (ticketType === "individual" && totalQuantity >= 3) ||
                          (ticketType === "family" && totalQuantity >= 10)
                        }
                        className="bg-white rounded-r border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlinePlus />
                      </button>
                    </div>
                    <p className="text-end mt-2 font-semibold text-lg text-black200">
                      ₦ {totalAmount.totalAge13to15.toLocaleString()}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="flex justify-between items-center my-2">
                  <div className="flex flex-col">
                    <p className="text-base font-medium">
                      16+
                      {/* <span className="text-grey100 text-sm"> (Guest)</span> */}
                    </p>
                    <div className={`flex`}>
                      {ticketPrice < 5000 && (
                        <p className="line-through  text-red-500 mr-2">
                          <span className="text-grey100">₦ 5,000</span>
                        </p>
                      )}

                      <p className="text-black500 font-medium text-base">
                        ₦ {ticketPrice}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="inline-flex items-center mt-2">
                      <button
                        onClick={() => handleDecrement("child")}
                        className="bg-white rounded-l border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlineMinusSmall />
                      </button>
                      <div className="bg-gray-100 border-t border-b border-gray-100 text-gray-600 hover:bg-gray-100 inline-flex items-center px-4 py-1 select-none">
                        {childQuantity}
                      </div>
                      <button
                        onClick={() => handleIncrement("child")}
                        disabled={
                          (ticketType === "individual" && totalQuantity >= 3) ||
                          (ticketType === "family" && totalQuantity >= 10)
                        }
                        className="bg-white rounded-r border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlinePlus />
                      </button>
                    </div>
                    <p className="text-end mt-2 font-semibold text-lg text-black200">
                      ₦ {totalAmount.totalChild.toLocaleString()}
                    </p>
                  </div>
                </div>

                <hr />
                <div className="flex justify-between items-center my-2">
                  <div className="flex flex-col">
                    <p className="text-base font-medium">
                      Adults
                      {/* <span className="text-grey100 text-sm"> (Guest)</span> */}
                    </p>
                    <div className={`flex`}>
                      {ticketPrice < 5000 && (
                        <p className="line-through  text-red-500 mr-2">
                          <span className="text-grey100">₦ 5,000</span>
                        </p>
                      )}

                      <p className="text-black500 font-medium text-base">
                        ₦ {ticketPrice}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="inline-flex items-center mt-2">
                      <button
                        onClick={() => handleDecrement("adult")}
                        className="bg-white rounded-l border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlineMinusSmall />
                      </button>
                      <div className="bg-gray-100 border-t border-b border-gray-100 text-gray-600 hover:bg-gray-100 inline-flex items-center px-4 py-1 select-none">
                        {adultQuantity}
                      </div>
                      <button
                        onClick={() => handleIncrement("adult")}
                        disabled={
                          (ticketType === "individual" && totalQuantity >= 3) ||
                          (ticketType === "family" && totalQuantity >= 10)
                        }
                        className="bg-white rounded-r border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlinePlus />
                      </button>
                    </div>
                    <p className="text-end mt-2 font-semibold text-lg text-black200">
                      ₦ {totalAmount.totalAdult.toLocaleString()}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="flex justify-between items-center my-4">
                  <p>Total</p>
                  <p>₦ {totalAmount.totalAmount.toLocaleString()}</p>
                </div>
              </div>
            </div>

            <div>
              <div>
                <label className="flex  mb-3 gap-x-3">
                  <input
                    type="checkbox"
                    name="marketing"
                    checked={checkedBoxes.marketing}
                    onChange={handleCheckboxChange}
                    className="bg-primary border border-gray-300 rounded-md checked:bg-primary checked:border-transparent focus:outline-none"
                  />
                  <span className="text-sm font-normal text-gray-700">
                    I consent to the use of of images/videos of attendees for
                    marketing, publications, or promotional materials as deemed
                    appropriate by{" "}
                    <span className="font-bold">STEM Africa Fest</span>.
                  </span>
                </label>
                <label className="flex cursor-pointer mb-3 space-x-3">
                  <input
                    type="checkbox"
                    name="terms"
                    checked={checkedBoxes.terms}
                    onChange={handleCheckboxChange}
                    className="bg-primary border border-gray-300 rounded-md checked:bg-primary checked:border-transparent focus:outline-none"
                  />
                  <span
                    className="text-base font-normal text-gray-700"
                    onClick={() => setOpenTermsModal(true)}
                  >
                    I agree to{" "}
                    <span className="text-primary">
                      STEM Africa Fest Terms & Conditions
                    </span>
                  </span>
                </label>
                {/* <p className="text-sm font-normal text-gray-700 cursor-pointer underline decoration-primary" checked={checkedBoxes.marketing}
                    onChange={handleCheckboxChange}>
                      <span className="underline" >
                        Click Here to read the  Terms and Condition
                      </span>                  
                </p> */}

                <p className="text-sm mb-2 font-normal text-gray-700  decoration-primary">
                  Please Select how you heard about STEM Africa Fest
                </p>
                <Select
                  style={{ width: "100%" }}
                  placeholder="How did you hear about us"
                  onChange={handleCategoryChange}
                  value={selectedCategory || undefined}
                >
                  <Option value="Instagram">Instagram</Option>
                  <Option value="Twitter">Twitter</Option>
                  <Option value="LinkedIn">LinkedIn</Option>
                  <Option value="WhatsApp">WhatsApp</Option>
                  <Option value="Email">Email</Option>
                  <Option value="School">School</Option>
                  <Option value="Friends">Friends</Option>
                  <Option value="Blogs">Blogs</Option>
                  <Option value="Others">Others</Option>
                </Select>
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between w-full gap-4 mt-8">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className={`py-3 px-4 bg-primary hover:bg-primary/70 focus:ring-primary focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-2 ${
                    isContinueButtonDisabled && "opacity-50 cursor-not-allowed"
                  }`}
                  disabled={isContinueButtonDisabled || loading}
                >
                  {loading ? (
                    <div className="flex justify-center">
                      {" "}
                      <ClipLoader color="#fff" />{" "}
                    </div>
                  ) : (
                    <p> Pay ₦ {totalAmount.totalAmount.toLocaleString()}</p>
                  )}
                </button>
                <button
                  type="button"
                  onClick={prevStep}
                  className="py-3 px-4 bg-white border border-primaryGrey text-grey100 w-full md:w-1/2 transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-1"
                >
                  Go back
                </button>
              </div>
              <TermsAndConditionModal
                visible={openTermsModal}
                termsChecked={termsChecked}
                setTermsChecked={setTermsChecked}
                onClose={() => setOpenTermsModal(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTicket;
