import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/svgs/Logo-2.svg";

const navLinks = [
  { to: "/", text: "Home" },
  { to: "/about", text: "About" },
  { to: "/join", text: "Join" },
  { to: "/faq", text: "FAQ" },
];

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const menuAnimation = useSpring({
    transform: isMenuOpen ? "translateX(0%)" : "translateX(-100%)",
  });

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <nav className="bg-white  shadow  px-4 xl:px-0">
        <div className="container mx-auto ">
          <div className="max-w-3xl">
            <div className="flex items-center justify-between  h-20">
              <div className="flex justify-center">
                <Link className="flex-shrink-0" to="https://steamfunfest.com/">
                  <img className="w-20 h-auto" src={Logo} alt="quiz-logo" />
                </Link>
              </div>
              <div className="hidden lg:block">
                {/* <div className="flex items-baseline gap-x-10">
                  {navLinks.map((link, index) => (
                    <NavLink
                      key={index}
                      to={link.to}
                      activeClassName="active-link"
                      className="text-black px-3 py-2  text-base font-medium hover:text-accessOrange"
                    >
                      {link.text}
                    </NavLink>
                  ))}
                </div> */}
              </div>

              {/* Responsive Menu Icon */}
              {/* <button
                className="lg:hidden text-gray-600  focus:outline-none"
                onClick={toggleMenu}
              >
                <svg
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
              </button> */}
            </div>
          </div>
        </div>
      </nav>

      {/* Responsive Menu */}
      <animated.div
        className="lg:hidden fixed top-0 left-0 w-full h-full z-50 bg-white"
        style={menuAnimation}
      >
        <div className="flex items-center justify-end p-4">
          <button
            className="text-gray-600  focus:outline-none"
            onClick={toggleMenu}
          >
            <svg
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div className="flex flex-col items-center space-y-4 pt-16">
          {/* Responsive Menu Links */}
          {/* {navLinks.map((link, index) => (
            <NavLink
              key={index}
              to={link.to}
              activeClassName="active-link"
              className="text-black px-3 py-2  text-base font-medium hover:text-accessOrange"
            >
              {link.text}
            </NavLink>
          ))} */}
        </div>
      </animated.div>
    </div>
  );
};

export default Navbar;
