import React from "react";
import CheckedIcon from "../assets/svgs/Checked_Icon.svg";
import { useLocation, useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const navigate = useNavigate();
  const trxref = sessionStorage.getItem("trxref");
  // const userId = sessionStorage.getItem("userId");

  return (
    <div className="containerOuter">
      <div className="containerInner shadow-2xl">
        <div className="w-full h-full text-center">
          <div className="flex flex-col justify-between">
            <div className="flex justify-center">
              <img src={CheckedIcon} alt="checked-icon" className="w-40 h-60" />
            </div>

            {/* <p className=" flex gap-2 justify-center items-center my-2 text-primary text-lg font-semibold text-gray-900">
              <BsFiletypePdf /> File size: 200mb
            </p> */}
            <p className="text-[#777980] font-black text-2xl">
              Download complete
            </p>
          </div>
          <p className="mt-4 text-[#ED6F1E] font-medium text-base animate-bounce">
            Your child could win prizes up to 2 million Naira in our{" "}
            <span className="font-semibold"> STEAM Pitch Competition!</span>
            <span className="ml-2 text-primary underline font-bold italic text-sm">
             
              <a
                href="https://stemafricafest.com/steam-pitch-competition/"                
              >
                Sign up Here
              </a>
            </span>
          </p>
          <div className="flex flex-col md:flex-row items-center justify-between w-full gap-4 mt-8">
            <a
              href="https://stemafricafest.com/"
              className="py-3 px-4 bg-primary hover:bg-primary focus:ring-primary focus:ring-offset-indigo-200 text-white w-full   transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-2"
            >
              Buy again
            </a>

            <button
              type="button"
              onClick={() => navigate(`/downloads?trxref=${trxref}`)}
              className={`py-3 px-4 bg-white border border-primaryGrey text-grey100 w-full md:w-1/2 transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-1`}
            >
              Go back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
