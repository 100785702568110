import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/Navbar";
import SuccessPage from "./components/SuccessPage";
import DownloadFilter from "./components/DownloadFilter";
import MultiStepForm from "./components/MultiStepForm";
import Facebook from "./tracking/Facebook";
import NotFound from "./pages/notFound";


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Facebook />
        <Routes>
          <Route path="/" element={<MultiStepForm />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/downloads" element={<DownloadFilter />} />
          <Route path="*" element={<NotFound />} />

        </Routes>
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
