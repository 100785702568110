import createApiClient from "./AxiosInstance";

// Create an Axios instance
const client = createApiClient();

export const api = {
  //LOGIN
  register: (payload) =>
    client
      .post("/user/steam-register", { ...payload })
      .then(({ data }) => data),

  recordDownload: (userId, payload) =>
    client
      .post(`/user/record-download/${userId}`, { ...payload })
      .then(({ data }) => data),

  getTickets: (userId, refId) =>
    client
      .get(`/user/steam-ticket/${userId}?txn_ref=${refId}`)
      .then(({ data }) => data),

  getFreeSpace: (location) =>
    client.get(`/user/free-space/${location}`).then(({ data }) => data),

  logVisitors: () =>
    client.get(`/telco/visitor-logger?version=steamafrica`).then(({ data }) => data),
};
