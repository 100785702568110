import React, { useState } from "react";
import { Modal } from "antd";

const sections = [
  {
    title: "Ticket Sales and Refunds",
    content: [
      "All ticket sales are final and non-refundable, except in the case of event cancellation.",
      "Tickets are transferable with prior notification to the organizers.",
    ],
  },
  {
    title: "Age Restrictions",
    content: [
      "The event is open to children aged 8-15 years. Children 7-14 must be accompanied by a responsible adult aged 18 or older.",
    ],
  },
  {
    title: "Entry and Attendance",
    content: [
      "Admission to the event is subject to the presentation of a valid ticket for each child and adult.",
    ],
  },
  {
    title: "Liability Waiver",
    content: [
      "Attendees participate in the event at their own risk. The organizers are not liable for any injuries, accidents, or damages incurred during the event.",
    ],
  },
  {
    title: "Code of Conduct",
    content: [
      "Attendees must adhere to a code of conduct promoting respectful and safe behavior towards others and the event premises.",
      "Disruptive behavior, including but not limited to harassment, violence, or vandalism, will result in immediate ejection from the event without a refund.",
    ],
  },
  {
    title: "Photography and Recording",
    content: [
      "Photography, videography, and recording devices are permitted for personal use only.",
      "Attendees consent to the use of their images for promotional purposes by the organizers.",
    ],
  },
  {
    title: "Event Changes or Cancellation",
    content: [
      "The organizers reserve the right to make changes to the event schedule, venue, or programming if necessary.",
      "In the event of cancellation, ticket holders will be notified promptly, and refunds or alternative arrangements will be provided.",
    ],
  },
  {
    title: "Lost or Stolen Items",
    content: [
      "The organizers are not responsible for any lost or stolen items during the event.",
      "Attendees are advised to keep their belongings secure at all times.",
    ],
  },
  {
    title: "Health and Safety",
    content: [
      "Attendees are expected to maintain good personal hygiene and adhere to health and safety guidelines provided by the organizers.",
      "In case of illness or medical emergency, attendees should seek assistance from event staff immediately.",
    ],
  },
  {
    title: "Compliance with Laws and Regulations",
    content: [
      "Attendees must comply with all applicable laws and regulations during the event.",
      "The organizers will not tolerate illegal or prohibited activities on the premises.",
    ],
  },
  {
    title: "Feedback",
    content: [
      "Attendees can share event feedback through designated channels.",
      "Feedback may be used to improve future events.",
    ],
  },
  {
    title: "Data Protection",
    content: [
      "Personal data will be handled according to the event's privacy policy.",
      "Attendees consent to the use of their data for event communications and marketing.",
    ],
  },
];

const TermsAndConditionModal = ({
  visible,
  onClose,
  termsChecked,
  setTermsChecked,
}) => {
  // for checkboxes

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setTermsChecked(checked);
  };
  return (
    <Modal
      //   title="Add new User"
      open={visible}
      onCancel={() => onClose(null)}
      footer={null}
      closable={false}
      width={800}
    >
      <div>
        <h1 className="text-center text-primary font-semibold text-2xl mb-4">
          Terms and Conditions
        </h1>
        <div>
          <p className="termsParagraph">
            Please carefully read these Terms and Conditions before purchasing a
            ticket or attending the STEM Africa event.
          </p>
          <p className="termsParagraph">
            You agree to be bound by these terms and conditions by purchasing a
            ticket or attending the event.
          </p>
        </div>

        {sections.map((section, index) => (
          <div key={index} className="my-8">
            <h2 className="termsHeader">{section.title}</h2>
            <div>
              {section.content.map((paragraph, idx) => (
                <p
                  key={idx}
                  className="termsParagraph flex items-center gap-4 ml-2"
                >
                  <span className="w-1 h-1 rounded-full bg-black"></span>{" "}
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
        ))}

        {/* <label className="flex cursor-pointer mb-3 space-x-3">
          <input
            type="checkbox"
            checked={termsChecked}
            onChange={handleCheckboxChange}
            className="bg-primary border border-gray-300 rounded-md checked:bg-primary checked:border-transparent focus:outline-none"
          />
          <span className="text-base font-normal text-gray-700">
            I agree to{" "}
            <span className="text-primary">
              STEM Africa Fest Terms & Conditions
            </span>
          </span>
        </label> */}
      </div>

      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => onClose(null)}
          className="py-3 px-4 bg-primary border border-primaryGrey text-white w-fit  transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-1"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default TermsAndConditionModal;
