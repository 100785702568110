
import React, { useEffect } from "react";
import Logo from "../assets/svgs/Logo-2.svg";
import StepProgress from "./StepProgress";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setTicketType
} from "../redux/features/ticketSlice";

const CardHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ticketType = searchParams.get("ticketType");
  const isShow = sessionStorage.getItem("show-progress");

  useEffect(() => {
    sessionStorage.setItem("ticket-type", ticketType);
    dispatch(setTicketType(ticketType))
  }, [location]);

  return (
    <div>
      <div className="hidden md:flex justify-center md:mb-5">
        <img className="w-20 h-auto" src={Logo} alt="quiz-logo" />
      </div>
      <h3 className="heading">STEM Africa Fest 2024</h3>
      <div className="mb-7">
        <div className="flex justify-between items-center">
          <div className="">
            <h1 className="text-base font-semibold text-black100">
              {ticketType === "individual"
                ? "Individual Tickets"
                : ticketType === "group"
                ? "Group Tickets"
                : ticketType === "family"
                ? "Family Tickets"
                : "Unknown Ticket Type"}
            </h1>

            <p className="text-sm font-medium text-grey100">Sponsor details</p>
          </div>
          {/* <div className={` ${!isShow ? "" : "hidden"}`}>
            <StepProgress />
            <p className="text-center text-xs font-medium text-grey100 mt-1">
              Tickets available
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CardHeader;
