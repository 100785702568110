import React, { useState, useEffect } from "react";
import Logo from "../assets/svgs/Logo-2.svg";
import { BsFiletypePdf } from "react-icons/bs";
import SingleSelectDropdown from "./SingleSelectDropdown";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { api } from "../api/auth";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom/dist";
import { setTicketArray } from "../redux/features/ticketSlice";
import { useSelector, useDispatch } from "react-redux";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { renderToString } from "react-dom/server";

import Ticket from "./Ticket";
import { ClipLoader } from "react-spinners";


const DownloadTypes = [
  { label: "All", value: "all" },
  { label: "Age 3 to 7", value: "age3to7" },
  { label: "Age 8 to 12", value: "age8to12" },
  { label: "Age 13 to 15", value: "age13to15" },
  { label: "Adult", value: "adult" },
  { label: "Child", value: "child" },
];

const DownloadFilter = () => {
  const tickets = useSelector((state) => state.tickets.ticketArray);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const userData = JSON.parse(sessionStorage.getItem("user"));
  const userId = userData?.id || searchParams.get("user_id");
  const trxref = searchParams.get("trxref") || searchParams.get("txn_ref");

  const [selectedDownloadType, setSelectedDownloadType] = useState("all");
  const [filteredTicketsLength, setFilteredTicketsLength] = useState([]);
  
  const { data,  refetch, isLoading} = useQuery(
    ["get-tickets", userId, trxref],
    async () => {
      const response = await api.getTickets(userId, trxref);
      dispatch(setTicketArray(response.tickets));
      setFilteredTicketsLength(response.tickets)
      return response.tickets;
    },
    {
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
  
  

  const handleSelectedDownloadType = (option) => {
    setSelectedDownloadType(option);
    let filteredTickets = [];

    switch (option.value) {
      case "all":
        filteredTickets = tickets;
        setFilteredTicketsLength(filteredTickets);
        toast.success("All tickets fetched");
        break;
      case "age3to7":
        filteredTickets = tickets.filter(
          (ticket) => ticket.age_range === "3-7"
        );
        setFilteredTicketsLength(filteredTickets);
        toast.success("Ag30to7 tickets fetched");
        break;
      case "age8to12":
        filteredTickets = tickets.filter(
          (ticket) => ticket.age_range === "8-12"
        );
        setFilteredTicketsLength(filteredTickets);
        toast.success("Age8to12 tickets fetched");
        break;
      case "age13to15":
        filteredTickets = tickets.filter(
          (ticket) => ticket.age_range === "13-15"
        );
        setFilteredTicketsLength(filteredTickets);
        toast.success("Age13to15 tickets fetched");
        break;
      case "adult":
        filteredTickets = tickets.filter(
          (ticket) => ticket.age_range === "adult"
        );
        setFilteredTicketsLength(filteredTickets);
        toast.success("Adult tickets fetched");
        break;
      case "child":
        filteredTickets = tickets.filter(
          (ticket) => ticket.age_range === "child"
        );
        setFilteredTicketsLength(filteredTickets);
        toast.success("Child tickets fetched");
        break;
      default:
        // Handle default case or invalid selection
        break;
    }
    // setFilteredTicketsLength(filteredTickets);
  };

  useEffect(() => {
   handleSelectedDownloadType(selectedDownloadType);
    sessionStorage.setItem("trxref", trxref);
    // sessionStorage.setItem("userId", userId);
  }, [selectedDownloadType]);

  const handleDownloadPDF = async () => {
    const pdf = new jsPDF("portrait");
    const ticketIdsArray = []; // Array to store ticket IDs

    // Group tickets into pages with 1 ticket per page
    for (let i = 0; i < filteredTicketsLength.length; i++) {
      const pageTickets = [filteredTicketsLength[i]];
      const ticketIds = pageTickets.map((ticket) => ticket.ticket_id);
      ticketIdsArray.push(...ticketIds); // Push ticket IDs into the array
      await addPageContent(
        pdf,
        pageTickets,
        ticketIds,
        ticketIdsArray
        // filteredTicketsLength[i].ticket_id
      );

      if (i + 1 < filteredTicketsLength.length) {
        pdf.addPage();
      }
    }

    pdf.save("steamFest-ticket.pdf");
  };

  const addPageContent = async (
    pdf,
    pageTickets,
    ticketIds,
    ticketIdsArray
  ) => {
    const margin = 0;
    let yPosition = margin;
    let canvas;

    try {
      // Iterate through each ticket
      for (const ticket of pageTickets) {
        // Render JSX to HTML string only for the PDF
        if (pdf) {
          const ticketHTML = renderToString(<Ticket ticket={ticket} />);
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = ticketHTML;
          const ticketDiv = tempDiv.firstChild;
          document.body.appendChild(ticketDiv);

          canvas = await html2canvas(ticketDiv);

          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            margin,
            yPosition,
            0,
            0
          );

          pdf.text(
            `Ticket ID: ${ticketIds}`,
            margin,
            yPosition + canvas.height + 2
          );
          document.body.removeChild(ticketDiv);
        }

        sendTicketIdToBackend(ticketIdsArray);

        // Adjust spacing between tickets and ID
        yPosition += canvas.height + 2;
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  
  


  const sendTicketIdToBackend = async (ticketIds) => {
    try {
      const response = await api.recordDownload(userId, { tickets: ticketIds });
      // toast.success(response.message);
      navigate("/success");
    } catch (error) {
      toast.error(error.message);
    }
  };

 
 

  return (
    <div className="containerOuter">
      {
        isLoading ? (<div className="flex justify-center mb-4">
        <ClipLoader color="#602977" />
      </div>) : (  <div className="containerInner shadow-2xl">
        {data?.length === 0 ? (
          <div className="flex flex-col items-center">
          <h1 className="text-2xl font-bold mb-4 animate-fade-in text-center">
            Please wait...🥳
          </h1>
          <div className="flex justify-center mb-4">
            <ClipLoader color="#602977" />
          </div>
          <p className="text-gray-600 text-center mb-2">We're verifying your payment</p>
          <p className="mb-4 text-gray-600 text-center text-xs">It's taking longer than expected... Please click the refresh button</p>
          <div className="flex justify-end">
          <button  onClick={() => refetch()} className="py-3 px-4 bg-primary hover:bg-primary focus:ring-primary focus:ring-offset-indigo-200 text-white w-fit transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-2">Refresh</button>
          </div>
        </div>
        ) : (
          <div className="w-full h-full text-center">
            <h2 className="text-[#DE4699] font-black text-2xl font-['Lemonada']">
              See you there! 🥳
            </h2>
            <div className="flex flex-col justify-between">
              <div className="flex justify-center">
                <img src={Logo} alt="checked-icon" className="w-60 h-60" />
              </div>
              <p className=" text-primary text-base font-semibold ">
                Download your tickets now! 
              </p>
              <p className="text-[#777980] font-normal text-sm">
                We've sent a link to your email at{" "}
                <span className="text-[#161721] font-medium">
                  {userData?.email}
                </span>{" "}
                and an sms to your phone number at
                <span className="text-[#161721] font-medium mx-1">
                  {userData?.phone_number}</span>. Enjoy the show!
              </p>

              <div>
                {/* <div className="flex justify-between items-center border border-primary rounded-lg p-2 mt-5">
                  <div className="flex flex-col items-start">
                    <span className="text-primary text-sm font-normal">
                      Total tickets
                    </span>
                    <span className="text-[#1D1F2C] text-base text-center font-bold">
                      {filteredTicketsLength.length}
                    </span>
                  </div>
                  <SingleSelectDropdown
                    options={DownloadTypes}
                    onSelect={handleSelectedDownloadType}
                    placeholder="All"
                    placeholderColor="text-white"
                    isFilterIcon
                    isBackgroundColor="bg-primary text-white"
                    isWidth="w-[120px] rounded-b-lg"
                  />
                </div> */}
                <div className="flex flex-col md:flex-row items-center justify-between w-full gap-4 mt-8">
                  <button
                    type="button"
                    onClick={handleDownloadPDF}
                    // onClick={convertToPDF}
                    disabled={filteredTicketsLength?.length === 0}
                    className={`py-3 px-4 bg-primary hover:bg-primary focus:ring-primary focus:ring-offset-indigo-200 text-white w-full   transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-2 ${
                      filteredTicketsLength?.length === 0 &&
                      "opacity-50 cursor-not-allowed"
                    }`}
                  >
                    {filteredTicketsLength?.length === 0 ? (
                      "No ticket available"
                    ) : (
                      <div className="flex items-center justify-center  gap-x-10">
                        Download{" "}
                        {filteredTicketsLength &&
                        filteredTicketsLength?.length === 1
                          ? "1 Ticket"
                          : (filteredTicketsLength &&
                              filteredTicketsLength?.length) + " Tickets"}
                        <BsFiletypePdf />
                      </div>
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="py-3 px-4 bg-white border border-primaryGrey text-grey100 w-full md:w-1/2 transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-1"
                  >
                    Go back
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>)
      }
    
    </div>
  );
};

export default DownloadFilter;
